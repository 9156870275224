import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Grid } from '@mui/material';
import { useEffect, useRef } from 'react';
import { FileEdit, FileUploadPreview } from './FileEditNew';

const DragableItem = ({
  src,
  onChange,
  onRemove,
}: {
  src: string;
  onChange: (newSrc: string, oldSrc: string) => void;
  onRemove: (removedUrl: string) => void;
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const el = ref.current;
    if (!el) return;
    return combine(
      draggable({
        element: el,
        getInitialData: () => ({ src }),
      }),
      dropTargetForElements({
        element: el,
        getData: () => ({ src }),
      }),
    );
  }, [src]);

  return (
    <FileEdit
      ref={ref}
      key={src}
      src={src}
      onChange={(newUrl) => onChange(newUrl, src)}
      onRemove={() => onRemove(src)}
    />
  );
};

const DragableFileEdits = ({
  children,
  onChange,
  onRemove,
  onDragAndDrop,
  onComplete,
  compressedFile,
}: {
  children: string[];
  onChange: (newSrc: string, oldSrc: string) => void;
  onRemove: (removedUrl: string) => void;
  onDragAndDrop: (newOrder: string[]) => void;
  onComplete: (url: string) => void;
  compressedFile?: File | null;
}) => {
  useEffect(() => {
    return monitorForElements({
      onDrop({ source, location }) {
        const destination = location.current.dropTargets[0];
        if (!destination) {
          return;
        }
        const destinationSrc = destination.data.src;
        const startSrc = source.data.src;
        if (typeof destinationSrc !== 'string' || typeof startSrc !== 'string') {
          return;
        }
        const updated = [...children];
        updated[children.indexOf(startSrc)] = destinationSrc;
        updated[children.indexOf(destinationSrc)] = startSrc;
        onDragAndDrop(updated);
      },
    });
  }, [children, onDragAndDrop]);

  return (
    <Grid
      container
      direction={'row'}
      sx={(theme) => ({
        gap: theme.spacing(0.5),
        width: '100%',
        alignItems: 'center',
      })}
    >
      {children.map((src) => (
        <Grid key={src} item sm={5.9} md={3.9} sx={{ width: '49%' }}>
          <DragableItem src={src} key={src} onChange={onChange} onRemove={onRemove} />
        </Grid>
      ))}
      {compressedFile && (
        <Grid item sm={5.9} md={3.9} sx={{ width: '49%' }}>
          <FileUploadPreview rawFile={compressedFile} onComplete={onComplete} />
        </Grid>
      )}
    </Grid>
  );
};

export default DragableFileEdits;
