import { Box, Typography } from '@mui/material';
import { FaRegClipboard, FaHashtag, FaMoneyBill } from 'react-icons/fa';
import { MdAccessTime, MdLocationOn, MdCreditCard } from 'react-icons/md';
import Address from 'components/Address';
import Files from 'components/Files';
import { useFeatures } from 'hooks/useFeatures';
import { DetailItem, FontStyle } from 'generated/graphql';
import ProductIcons from '../ProductIcons';

const RowDetailItem = ({ data }: { data: DetailItem }) => {
  const { isEnabled } = useFeatures();
  const isDispatchRedesignEnabled = isEnabled('DispatchRedesign');
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={(theme) => ({
          marginRight: theme.spacing(1),
          minWidth: 32,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          fontSize: '1rem',
          color: theme.palette.grey[700],
        })}
      >
        {data.icon.toLowerCase().includes('products') && (
          <Box sx={(theme) => ({ marginTop: theme.spacing(-0.75) })}>
            <ProductIcons value={data.icon} />
          </Box>
        )}
        {data.icon.toLowerCase().includes('time') && <MdAccessTime />}
        {data.icon.toLowerCase().includes('cash') && <FaMoneyBill />}
        {data.icon.toLowerCase().includes('credit') && <MdCreditCard />}
        {data.icon.toLowerCase().includes('location') && <MdLocationOn />}
        {data.icon.toLowerCase().includes('notes') && <FaRegClipboard />}
        {data.icon.toLowerCase().includes('hash') && <FaHashtag />}
      </Box>
      {data.icon === 'LOCATION' ? (
        <Address
          color="inherit"
          value={data.info.value}
          variant={data.info.style === FontStyle.Highlighted ? 'h6' : 'body1'}
          sx={(theme) => ({
            padding: data.info.style === FontStyle.Bold ? theme.spacing(0.8, 0) : undefined,
            fontWeight:
              data.info.style === FontStyle.Bold
                ? theme.typography.fontWeightMedium
                : theme.typography.fontWeightRegular,
            '& svg': {
              color: theme.palette.primary.main,
            },
          })}
        >
          {data.info.label.length > 0 ? `${data.info.label}: ` : ''}
          {data.info.value}
        </Address>
      ) : (
        <Typography
          variant={data.info.style === FontStyle.Highlighted ? 'h6' : 'body1'}
          sx={(theme) =>
            data.info.style === FontStyle.Bold
              ? {
                  padding: theme.spacing(0.8, 0),
                  fontWeight: theme.typography.fontWeightMedium,
                }
              : {}
          }
        >
          {data.info.label.length > 0 ? `${data.info.label}: ` : ''}
          {data.info.label.toLocaleLowerCase() === 'images' ? (
            <Files files={data.info.value.split(';').slice(0, -1)} />
          ) : (
            data.info.value.split('\n').map((line) => (
              <>
                {line}
                <br />
              </>
            ))
          )}
        </Typography>
      )}
      {isDispatchRedesignEnabled && data.images?.length > 0 && (
        <Files files={data.images} reference={data.info.value} />
      )}
    </Box>
  );
};

export default RowDetailItem;
