import dayjs from 'dayjs';
import { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import { useFeatures } from 'hooks/useFeatures';
import useUser from 'hooks/useUser';
import { MarketLive, useMarketLiveSubscription } from 'generated/graphql';

export const MARKET_MORNING_START = dayjs().startOf('day').hour(8).toISOString();
export const MARKET_MORNING_END = dayjs().startOf('day').hour(10).minute(30).toISOString();
export const MARKET_AFTERNOON_START = dayjs().startOf('day').hour(14).toISOString();
export const MARKET_AFTERNOON_END = dayjs().startOf('day').hour(16).minute(30).toISOString();

enum MarketStatus {
  MORNING = 'morning market',
  AFTERNOON = 'afternoon market',
  NEITHER = 'neither',
}

const getMarketStatus = () => {
  const now = dayjs();

  if (now.isAfter(MARKET_MORNING_START) && now.isBefore(MARKET_MORNING_END)) {
    return MarketStatus.MORNING;
  } else if (now.isAfter(MARKET_AFTERNOON_START) && now.isBefore(MARKET_AFTERNOON_END)) {
    return MarketStatus.AFTERNOON;
  } else {
    return MarketStatus.NEITHER;
  }
};

const isMarketOpen = () => {
  return getMarketStatus() !== MarketStatus.NEITHER;
};

const isWeekend = (date = dayjs()) => {
  const dayOfWeek = date.day();

  return dayOfWeek === 0 || dayOfWeek === 6;
};

const getCurrentMarketPeriod = () => {
  const marketStatus = getMarketStatus();
  const end = marketStatus === MarketStatus.MORNING ? MARKET_MORNING_END : MARKET_AFTERNOON_END;
  const start = marketStatus === MarketStatus.MORNING ? MARKET_MORNING_START : MARKET_AFTERNOON_START;
  const period = marketStatus === MarketStatus.MORNING ? 'morning' : 'afternoon';
  const currentTime = dayjs();

  const timeLeftInSeconds = dayjs(end).diff(currentTime, 'seconds');

  const hours = Math.floor(timeLeftInSeconds / 3600);
  const minutes = Math.floor((timeLeftInSeconds % 3600) / 60);
  const seconds = timeLeftInSeconds % 60;

  let timeLeft = [];
  if (hours > 0) {
    timeLeft.push(`${String(hours).padStart(2, '0')}h`);
  }
  if (minutes > 0) {
    timeLeft.push(`${String(minutes).padStart(2, '0')}m`);
  }
  timeLeft.push(`${String(seconds).padStart(2, '0')}s`);

  return {
    end,
    start,
    period,
    time: currentTime.toISOString(),
    timeLeft: timeLeft.join(''),
  };
};

const GlobalSubscriptionsContext = createContext<
  | {
      marketLive?: MarketLive;
      loading: boolean;
      error?: Error;
    }
  | undefined
>(undefined);

export const GlobalSubscriptionsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useUser();
  const { isEnabled } = useFeatures();
  const isMarketLiveCentralisedEnabled = isEnabled('MarketLiveCentralised');
  const [result] = useMarketLiveSubscription({
    pause: !user?.email || !isMarketLiveCentralisedEnabled,
  });

  const { data, fetching, error } = result;

  const [currentMarketPeriod, setCurrentMarketPeriod] = useState(getCurrentMarketPeriod());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMarketPeriod(getCurrentMarketPeriod());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const value = {
    marketLive: isMarketLiveCentralisedEnabled
      ? data?.marketLive
      : {
          current: currentMarketPeriod,
          isOpen: isMarketOpen(),
          isOpeningTomorrow: !isWeekend(dayjs().add(1, 'day')),
          isWeekend: isWeekend(),
          schedule: [
            {
              start: MARKET_MORNING_START,
              end: MARKET_MORNING_END,
            },
            {
              start: MARKET_AFTERNOON_START,
              end: MARKET_AFTERNOON_END,
            },
          ],
        },
    loading: fetching,
    error: error?.networkError,
  };

  return <GlobalSubscriptionsContext.Provider value={value}>{children}</GlobalSubscriptionsContext.Provider>;
};

export const useGlobalSubscriptions = () => {
  const context = useContext(GlobalSubscriptionsContext);
  if (!context) {
    throw new Error('useGlobalSubscriptions must be used within a GlobalSubscriptionsProvider');
  }
  return context;
};
