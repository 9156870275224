import { Box, CircularProgress, Snackbar, Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useEffect, useState, useRef, ChangeEventHandler, ElementRef, forwardRef } from 'react';
import { MdDelete, MdDownload, MdEdit, MdOutlinePictureAsPdf } from 'react-icons/md';
import { storage } from 'services/firebase';
import { v4 as uuidv4 } from 'uuid';
import IconButton from 'components/IconButton';
import { BaseTheme } from 'config/base-theme';
import { downloadFile } from 'utils/download-file';

const iconButtonStyle = (theme: BaseTheme) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
});

export const FileUploadPreview = ({
  src = '',
  rawFile,
  onComplete,
}: {
  src?: string;
  rawFile: File;
  onComplete(value: string): void;
}) => {
  const [progress, setProgress] = useState<null | number>(null);
  const [msg, setMsg] = useState('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const handleUpload = async () => {
      try {
        setProgress(0);
        setUploading(true);
        const extension = rawFile.name.split('.').pop();
        const storageRef = ref(storage, `images/${uuidv4()}.${extension}`);
        const uploadTask = uploadBytesResumable(storageRef, rawFile, {
          customMetadata: {
            originalName: rawFile.name ?? '',
            extension: extension ?? '',
          },
        });

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            setProgress(Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
          },
          (error) => {
            switch (error.code) {
              case 'storage/unauthorized':
                setMsg('Uploading: unauthorized');
                break;
              case 'storage/canceled':
                setMsg('Uploading: canceled');
                break;
              case 'storage/unknown':
              default:
                setMsg('Failed to upload file');
                break;
            }
            setProgress(null);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            onComplete(downloadURL);
            setUploading(false);
            setMsg('');
          },
        );
      } catch (error) {
        setMsg('Failed to upload file');
        setUploading(false);
      }
    };
    if (rawFile) handleUpload();
  }, [rawFile, onComplete]);

  if (uploading || !src) {
    return (
      <Box
        sx={(theme) => ({ margin: theme.spacing(1, 0) })}
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {msg && <Alert severity="error">{msg}</Alert>}
        {progress !== null && (
          <>
            <CircularProgress variant="determinate" value={progress} size={48} />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="caption" component="div" color="textSecondary">{`${progress}%`}</Typography>
            </Box>
          </>
        )}
      </Box>
    );
  }

  return <img src={src} alt="uploading" />;
};
export const FileEdit = forwardRef(function FileEdit(
  {
    src,
    onChange,
    onRemove,
  }: {
    src: string;
    onChange: (value: string) => void;
    onRemove: () => void;
  },
  ref,
) {
  const [showError, setShowError] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [showFallback, setShowFallback] = useState(false);
  const inputRef = useRef<ElementRef<'input'>>(null);
  const [file, setFile] = useState<File | null>(null);

  const handleEditClick = () => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.click();
  };

  const handleDownload = async () => {
    try {
      setDownloading(true);
      const response = await fetch(src);
      const contentType = response.headers.get('Content-Type');
      const fileType = contentType?.startsWith('image/') ? 'png' : 'pdf';
      await downloadFile(src, `${uuidv4()}.${fileType}`);
    } catch (error) {
      setShowError(true);
    } finally {
      setDownloading(false);
    }
  };

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = async (event) => {
    if (!event.target.files || !event.target.files.length) {
      setFile(null);
      return;
    }
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    if (src) setShowFallback(false);
  }, [src]);

  return (
    <>
      <input type="file" onChange={handleFileUpload} style={{ display: 'none' }} ref={inputRef} />
      {src && (
        <Box
          sx={(theme) => ({
            border: `1px solid ${theme.palette.grey[300]}`,
            backgroundColor: theme.palette.grey[100],
            borderRadius: theme.spacing(0.75),
            overflow: 'hidden',
            height: 160,
            position: 'relative',
            marginBottom: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.grey[700],
          })}
        >
          {showFallback ? (
            <MdOutlinePictureAsPdf size={40} />
          ) : (
            <Box
              ref={ref}
              component="img"
              sx={{ width: '100%', height: '100%', objectFit: 'contain', objectPosition: 'center' }}
              src={src}
              alt=""
              onError={() => setShowFallback(true)}
            />
          )}
          <Stack
            direction="column"
            justifyContent="space-between"
            gap={0.5}
            sx={(theme) => ({
              position: 'absolute',
              right: theme.spacing(0.5),
              paddingTop: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
              height: '100%',
            })}
          >
            <IconButton disabled={isDownloading} onClick={handleEditClick} sx={iconButtonStyle}>
              <MdEdit size={20} />
            </IconButton>
            <IconButton disabled={isDownloading} onClick={handleDownload} sx={iconButtonStyle}>
              <MdDownload size={20} />
            </IconButton>
            <IconButton
              disabled={isDownloading}
              onClick={onRemove}
              sx={(theme) => ({
                color: theme.palette.common.white,
                backgroundColor: theme.palette.error.main,
                '&:hover': {
                  backgroundColor: theme.palette.error.dark,
                },
              })}
            >
              <MdDelete size={20} />
            </IconButton>
          </Stack>
        </Box>
      )}
      {file && <FileUploadPreview rawFile={file} onComplete={onChange} />}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showError}
        autoHideDuration={3000}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="error">
          Failed to download file
        </Alert>
      </Snackbar>
    </>
  );
});
