import { Snackbar } from '@mui/material';
import React, { useState, useCallback, createContext, useContext } from 'react';

const SnackbarContext = createContext<{ showSnackbar: (msg: string) => void; hideSnackbar: () => void } | undefined>(
  undefined,
);

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const showSnackbar = useCallback((msg: string) => {
    setMessage(msg);
    setOpen(true);
  }, []);

  const hideSnackbar = useCallback(() => {
    setMessage(null);
    setOpen(false);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setMessage(null);
  }, []);

  return (
    <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
      {children}
      <Snackbar open={open} onClose={handleClose} message={message} />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
