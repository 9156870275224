import {
  CircularProgress,
  alpha,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Stack,
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { CUSTOM_SCHEME_PREFIX } from 'services/api-constants';
import { getPrefixedRoute } from 'services/theming';
import Button from 'components/Button';
import DynamicButton from 'components/DynamicButton';
import ErrorSnackbar from 'components/ErrorSnackbar';
import { FormDialogState } from 'components/FormDialog';
import { Hide } from 'components/Hide';
import IconButton from 'components/IconButton';
import LazyFormDialog from 'components/LazyFormDialog';
import { QualityChipVariant, QualityChips } from 'components/QualityChip';
import { defaultFormDialogState } from 'components/default-form-dialog-state';
import { BaseTheme } from 'config/base-theme';
import { useFeatures } from 'hooks/useFeatures';
import useHandleCustomSchemeAndQuery from 'hooks/useHandleCustomSchemeAndQuery';
import useIsViewport from 'hooks/useIsViewport';
import { mergeSx } from 'utils/merge-sx';
import {
  Action,
  FontStyle,
  FontType,
  TableCellType,
  TableColumn,
  Shipment,
  Button as ButtonType,
  RowDataType,
  Rows,
  TableCell as TableCellData,
} from 'generated/graphql';
import ActionList from '../ActionList';
import BtnIcon from '../BtnIcon';
import TableDialog from '../TableDialog';
import BankDepositDetailsDialog from './BankDepositDetailsDialog';
import RowDetailItem from './RowDetailItem';
import useSeeShipment from './useSeeShipment';

const boxStyle = (theme: BaseTheme) => ({
  borderCollapse: 'separate',
  padding: theme.spacing(1.2, 1.5, 0),
  background: theme.palette.grey[100],
  borderRadius: theme.spacing(1),
});

const cellStyle = (theme: BaseTheme, cellData: TableCellData) => ({
  ...(cellData.fontStyle === FontStyle.Bold && {
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0.8, 0),
  }),
  ...(cellData.fontType === FontType.Normal && { fontFamily: theme.typography.fontFamily }),
  ...(cellData.type === TableCellType.Icon && { width: theme.spacing(5) }),
  [theme.breakpoints.down('md')]: {
    width: 72,
    padding: theme.spacing(0.5),
  },
});

const isSingleActionButton = (button: ButtonType) =>
  button.actions.length === 1 && (!button.actions[0].group || button.actions[0].group?.length === 0);

const isCustomScheme = (url: string) => url.startsWith(CUSTOM_SCHEME_PREFIX.Dialog);

const ShipmentRowDetail = ({
  data,
  eTag,
  refresh,
  isFetching,
  onHide,
  onDisable,
  isAllDisabled = false,
}: {
  data: Shipment;
  eTag: string;
  refresh(): void;
  isFetching?: boolean;
  isAllDisabled?: boolean;
  onHide?: (hide: boolean) => void;
  onDisable?: (disable: boolean) => void;
}) => {
  const isMobile = useIsViewport();
  const [buttonsLoading, setButtonsLoading] = useState(new Array(data.buttons.length).fill(false));
  const [buttonIndex, setButtonIndex] = useState<number>();
  const [actionsOpen, setActionsOpen] = useState(new Array(data.buttons.length).fill(false));

  const [openRows, setOpenRows] = useState<string[]>([]);
  const [formDialog, setFormDialog] = useState<FormDialogState>(defaultFormDialogState);
  const [hasRowUpdates, setHasRowUpdates] = useState(false);

  const isDisabled = isAllDisabled || (isFetching && hasRowUpdates);

  const { isEnabled } = useFeatures();
  const isHistoricalOrdersEnabled = isEnabled('HistoricalOrders');

  const { handleSchemeOrQuery, errorMessage, isLoading, paymentDetails, tableDialog, setErrorMessage } =
    useHandleCustomSchemeAndQuery(refresh);

  useEffect(() => {
    if (buttonIndex && buttonsLoading[buttonIndex] !== isLoading) {
      const buttonsLoadingCopy = [...buttonsLoading];
      buttonsLoadingCopy[buttonIndex] = isLoading;
      setButtonsLoading(buttonsLoadingCopy);

      if (!isLoading) {
        setButtonIndex(undefined);
      }
    }
  }, [isLoading, buttonIndex, buttonsLoading]);

  useEffect(() => {
    setHasRowUpdates(false);
  }, [data]);

  useSeeShipment(data.id);

  const clearError = () => setErrorMessage(undefined);

  const handleRowToggle = (id: string) => {
    setOpenRows((prev) => {
      if (prev.includes(id)) {
        return prev.filter((rowId) => rowId !== id);
      }
      return [...prev, id];
    });
  };

  const handleButtonClick = async (event: React.ChangeEvent<{}>, button: ButtonType, buttonIndex: number) => {
    if (!button.actions.length) {
      return;
    }

    const hasSingleAction = isSingleActionButton(button);

    if (hasSingleAction) {
      const action = button.actions[0];
      const { url, query } = action;
      const result = await handleSchemeOrQuery(url, query, { setFormDialog: setFormDialog });
      if (result?.error) {
        setResetButtonValues(true);
        return;
      }
      if (isCustomScheme(url)) {
        event.stopPropagation();
        setHasRowUpdates(true);
      }
      return;
    }

    const at = [...actionsOpen];
    at[buttonIndex] = true;
    setActionsOpen(at);
  };

  const [resetButtonValues, setResetButtonValues] = useState(false);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={7}>
        <Table
          size="small"
          sx={(theme) => ({
            ...boxStyle(theme),
            padding: theme.spacing(1.2, 1.5, 1),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1.2, 0, 2),
              background: 'transparent',
            },
          })}
        >
          <TableHead>
            <TableRow>
              {data.columns.map((column: TableColumn, i) => (
                <TableCell
                  key={`${column.value + i}`}
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    padding: theme.spacing(0.25, 0.5, 0.25, 0.5),
                  })}
                  align={column.align.toLowerCase() as 'left' | 'right'}
                >
                  {column.value}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rows?.map((row, i) => (
              <TableRow key={i}>
                {row.map((cellData, j) => {
                  let cell;
                  if (cellData.type === TableCellType.Icon) {
                    cell = (
                      <TableCell
                        key={`${cellData.value + j}`}
                        sx={(theme) => cellStyle(theme, cellData)}
                        align={cellData.align.toLowerCase() as 'left' | 'right'}
                      >
                        {cellData.value.length > 0 && (
                          <Box
                            component="img"
                            src={cellData.value}
                            alt=""
                            sx={(theme) => ({
                              verticalAlign: 'middle',
                              width: theme.spacing(5),
                              height: theme.spacing(5),
                              borderRadius: theme.spacing(1),
                            })}
                          />
                        )}
                      </TableCell>
                    );
                  } else {
                    cell = (
                      <TableCell
                        key={`${cellData.value + j}`}
                        sx={(theme) => cellStyle(theme, cellData)}
                        align={cellData.align.toLowerCase() as 'left' | 'right'}
                      >
                        {cellData.value}
                      </TableCell>
                    );
                  }
                  return <Fragment key={j}>{cell}</Fragment>;
                })}
              </TableRow>
            ))}
            {data.expandableRows.map((row, index) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    key={row.id}
                    sx={(theme) => ({
                      cursor: 'pointer',
                      background: row.highlighted ? alpha(theme.palette.info.main, 0.3) : '',
                    })}
                    onClick={() => handleRowToggle(row.id)}
                  >
                    {row.cells.map((cellData, j) => {
                      const align = cellData.align.toLowerCase() as 'left' | 'right';
                      if (cellData.type === TableCellType.Icon) {
                        return (
                          <TableCell
                            sx={(theme) => cellStyle(theme, cellData)}
                            key={`${cellData.value + j}`}
                            align={align}
                          >
                            {cellData.value.length > 0 && (
                              <Box
                                component="img"
                                src={cellData.value}
                                alt=""
                                sx={(theme) => ({
                                  verticalAlign: 'middle',
                                  width: theme.spacing(5),
                                  height: theme.spacing(5),
                                  borderRadius: theme.spacing(1),
                                })}
                              />
                            )}
                          </TableCell>
                        );
                      } else if (cellData.type === TableCellType.Chip) {
                        return (
                          <TableCell
                            key={`${cellData.value + j}`}
                            sx={mergeSx((theme) => cellStyle(theme, cellData))}
                            align={'center'}
                          >
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <QualityChips
                                chips={cellData.buttons}
                                variant={
                                  isMobile ? QualityChipVariant.BorderedCollapsed : QualityChipVariant.BorderedExpanded
                                }
                              />
                            </Box>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={`${cellData.value + j}`}
                          sx={(theme) => cellStyle(theme, cellData)}
                          align={align}
                        >
                          {/* @ts-ignore */}
                          {j === 0 && row.data !== RowDataType.None && (
                            <Hide>
                              <IconButton size="small" sx={{ padding: 0, marginRight: 0.5 }}>
                                {openRows.includes(row.id) ? (
                                  <MdKeyboardArrowUp size={20} />
                                ) : (
                                  <MdKeyboardArrowDown size={20} />
                                )}
                              </IconButton>
                            </Hide>
                          )}
                          <Box
                            sx={(theme) => ({
                              display: 'inline-block',
                              [theme.breakpoints.down('md')]: {
                                maxWidth: 160,
                                wordWrap: 'break-word',
                              },
                            })}
                          >
                            {cellData.value}
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {openRows.includes(row.id) &&
                    (row.data as Rows).rows &&
                    (row.data as Rows).rows?.map((subRow) => (
                      <TableRow key={subRow.id} sx={(theme) => ({ background: theme.palette.common.white })}>
                        {subRow.cells.map((cellData, j) => {
                          return (
                            <TableCell
                              key={`${cellData.value + j}`}
                              sx={(theme) => cellStyle(theme, cellData)}
                              align={cellData.align.toLowerCase() as 'left' | 'right'}
                            >
                              {cellData.value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12} md={5}>
        <Box
          sx={(theme) => ({
            ...boxStyle(theme),
            [theme.breakpoints.down('md')]: {
              margin: theme.spacing(-6, 0, 0),
            },
          })}
        >
          {data.items.map((item, i) => (
            <RowDetailItem data={item} key={`${item.info.value + i}`} />
          ))}
          <Stack
            direction="row"
            justifyContent="space-around"
            flexWrap="wrap"
            sx={(theme) => ({
              margin: theme.spacing(1, -1.5, 0),
            })}
          >
            {data.buttons.map((button, i) => {
              const hasSingleAction = isSingleActionButton(button);
              const isLinkButton =
                hasSingleAction && Boolean(button.actions[0].url) && !isCustomScheme(button.actions[0].url);

              return (
                <Box key={`${button.value + i}`} sx={{ margin: 0.75, flex: 1, minWidth: '35%', maxWidth: 180 }}>
                  <>
                    <Box sx={{ position: 'relative' }}>
                      {isLinkButton ? (
                        <Button
                          size={isMobile ? 'small' : 'medium'}
                          variant={button.variant.toLowerCase() as 'text' | 'outlined' | 'contained'}
                          color={button.color.toLowerCase() as 'primary' | 'secondary'}
                          startIcon={<BtnIcon icon={button.icon} />}
                          sx={{ width: '100%' }}
                          disabled={buttonsLoading[i] || isDisabled}
                          href={getPrefixedRoute(button.actions[0].url)}
                        >
                          {button.value.length > 0 ? button.value : button.actions[0]?.value}
                        </Button>
                      ) : isHistoricalOrdersEnabled ? (
                        <DynamicButton
                          button={button}
                          identifier={data.id}
                          eTag={eTag}
                          reset={resetButtonValues}
                          onReset={() => setResetButtonValues(false)}
                          onHide={onHide}
                          onDisable={onDisable}
                          size={isMobile ? 'small' : 'medium'}
                          startIcon={<BtnIcon icon={button.icon} />}
                          fetching={isFetching}
                          sx={{ width: '100%' }}
                          disabled={buttonsLoading[i] || isDisabled}
                          onClick={(e) => handleButtonClick(e, button, i)}
                        >
                          {button.value}
                        </DynamicButton>
                      ) : (
                        <Button
                          size={isMobile ? 'small' : 'medium'}
                          variant={button.variant.toLowerCase() as 'text' | 'outlined' | 'contained'}
                          color={button.color.toLowerCase() as 'primary' | 'secondary'}
                          startIcon={<BtnIcon icon={button.icon} />}
                          sx={{ width: '100%' }}
                          disabled={buttonsLoading[i] || isDisabled}
                          onClick={(e) => handleButtonClick(e, button, i)}
                        >
                          {button.value}
                        </Button>
                      )}
                      {buttonsLoading[i] && (
                        <CircularProgress
                          size={24}
                          color={button.color.toLowerCase() as 'primary' | 'secondary'}
                          sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: -1.5, marginLeft: -1.5 }}
                        />
                      )}
                    </Box>
                    <ActionList
                      open={actionsOpen[i]}
                      button={button}
                      onClose={(action?: Action) => {
                        const updatedActionsOpen = [...actionsOpen];
                        updatedActionsOpen[i] = false;
                        setActionsOpen(updatedActionsOpen);
                        if (!action) return;

                        handleSchemeOrQuery(action.url, action.query, { setFormDialog: setFormDialog });
                        setButtonIndex(i);
                      }}
                    />
                  </>
                </Box>
              );
            })}
          </Stack>
          {tableDialog.content && (
            <TableDialog
              data={tableDialog.content}
              onClose={() => {
                tableDialog.clear();
                refresh();
              }}
            />
          )}
        </Box>
        <ErrorSnackbar isOpen={!!errorMessage} onClose={clearError}>
          {errorMessage!}
        </ErrorSnackbar>
        {paymentDetails.open && (
          <BankDepositDetailsDialog shipmentId={paymentDetails.id} onClose={paymentDetails.onClose} />
        )}
        <LazyFormDialog
          type={formDialog.type}
          editIDs={formDialog.editIDs}
          open={formDialog.open}
          onClose={() => {
            setFormDialog(defaultFormDialogState);
          }}
          onSubmit={() => {
            setFormDialog(defaultFormDialogState);
            refresh();
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ShipmentRowDetail;
