import { devtoolsExchange } from '@urql/devtools';
import { persistedExchange } from '@urql/exchange-persisted';
import { createClient as createWSClient, SubscribePayload } from 'graphql-ws';
import authEx, { getToken } from 'services/auth';
import { createClient, fetchExchange, cacheExchange as defaultCacheExchange, subscriptionExchange } from 'urql';

export const makeClient = () => {
  const wssURL = import.meta.env.REACT_APP_GAPI_URL!.replace('http', 'ws');
  const wsClient = createWSClient({
    url: wssURL,
    connectionParams: async () => {
      const token = await getToken();
      return { Authorization: `Bearer ${token}` };
    },
  });
  return createClient({
    url: import.meta.env.REACT_APP_GAPI_URL!,
    requestPolicy: 'cache-and-network',
    exchanges: [
      devtoolsExchange,
      defaultCacheExchange,
      authEx,
      persistedExchange({
        preferGetForPersistedQueries: true,
      }),
      subscriptionExchange({
        forwardSubscription(operation) {
          return {
            subscribe: (sink) => {
              const dispose = wsClient.subscribe(operation as SubscribePayload, sink);
              return {
                unsubscribe: dispose,
              };
            },
          };
        },
      }),
      fetchExchange,
    ],
    fetchOptions: () => ({
      credentials: 'include',
      headers: { Authorization: 'unauthenticated' },
    }),
  });
};
