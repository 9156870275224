import React, { createContext, useContext, useMemo, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, useProfileSwitchOptionsQuery } from 'generated/graphql';
import useSearch from './useSearch';
import useUser from './useUser';

interface ProfileConfigContextType {
  data?: Button;
  loading: boolean;
  profileID?: string;
}

const extractProfileIDFromUrl = (url: string) => {
  const match = url.match(/profile\/(\d+)(\/|$)/);
  return match ? match[1] : null;
};

export const ProfileConfigContext = createContext<ProfileConfigContextType>({
  data: undefined,
  loading: false,
  profileID: undefined,
});

export const ProfileConfigProvider = ({ children }: { children: ReactNode }) => {
  const { isAdmin, user } = useUser();
  const [getParam] = useSearch();

  const location = useLocation();
  const extractedProfileID = extractProfileIDFromUrl(location.pathname);
  const profileID =
    getParam('profileID') ??
    getParam('profile') ??
    getParam('sellerUUID') ??
    extractedProfileID ??
    user?.profileId.toString();

  const [result] = useProfileSwitchOptionsQuery({
    variables: {
      profileID: Number(profileID),
    },
    pause: !isAdmin || profileID?.toString() === user?.profileId.toString(),
  });

  const value = useMemo(
    () => ({
      data:
        profileID && profileID.toString() !== user?.profileId.toString()
          ? result.data?.profileSwitchOptions
          : undefined,
      loading: !!result.fetching,
      profileID: profileID,
    }),
    [result, profileID, user?.profileId],
  );

  return <ProfileConfigContext.Provider value={value}>{children}</ProfileConfigContext.Provider>;
};

const useProfileConfig = () => {
  const context = useContext(ProfileConfigContext);
  if (!context) {
    throw new Error('useProfileConfig must be used within a ProfileConfigProvider');
  }
  return context;
};

export default useProfileConfig;
