import { Box, Stack } from '@mui/material';
import { MdOutlineImageNotSupported } from 'react-icons/md';
import ProductIcon from 'components/ProductIcon/ProductIcon';
import { QualityChipVariant, QualityChips } from 'components/QualityChip';
import { baseTheme } from 'config/base-theme';
import { useFeatures } from 'hooks/useFeatures';
import useImageThumbnail from 'hooks/useImageThumbnail';
import { ButtonColor, MarketImage } from 'generated/graphql';

const DEFAULT_MARKET_IMAGE_SIZE = 64;

export const BuyersMarketImage = ({
  marketImage,
  sku = '',
  size = DEFAULT_MARKET_IMAGE_SIZE,
  noClip = false,
  thumbnail,
  qualityChipVariant = QualityChipVariant.BadgeNoLabel,
  showCaption = false,
}: {
  marketImage?: MarketImage;
  sku?: string;
  size?: number;
  noClip?: boolean;
  thumbnail?: string;
  qualityChipVariant?: QualityChipVariant;
  showCaption?: boolean;
}) => {
  const { isEnabled } = useFeatures();
  const isOpsPolishEnabled = isEnabled('OpsPolish');

  const thumbnailSRC = useImageThumbnail(marketImage?.image, thumbnail);
  const src = thumbnail ? thumbnailSRC : marketImage?.image;
  const hasCaption = marketImage?.caption && marketImage.caption.length > 0;

  return (
    <Box sx={{}}>
      <Box sx={{ position: 'relative' }}>
        {marketImage?.badgeColor && (
          <QualityChips
            chips={[
              {
                color: marketImage?.badgeColor, // No QualityChip.value since it won't be used
                value: marketImage?.badgeLabel,
              },
            ]}
            variant={qualityChipVariant}
          />
        )}
        {src && src?.length > 0 ? (
          <Box
            component="img"
            src={src}
            alt="Product icon"
            sx={(theme) => ({
              width: size,
              height: size,
              objectFit: noClip ? 'contain' : 'cover',
              objectPosition: 'center',
              borderRadius: theme.spacing(1),
              backgroundColor: theme.palette.grey[200],
            })}
          />
        ) : (
          <Box sx={{ minWidth: size, minHeight: size, alignContent: 'center' }}>
            <Stack sx={{ minWidth: size, minHeight: size }} direction="row" alignItems="center" justifyContent="center">
              {sku.length > 0 ? (
                <ProductIcon sku={sku} size={40} />
              ) : (
                <MdOutlineImageNotSupported
                  size={40}
                  style={{
                    color: baseTheme.palette.grey[400],
                  }}
                />
              )}
            </Stack>
          </Box>
        )}
        {isOpsPolishEnabled && showCaption && hasCaption && (
          <QualityChips
            chips={[
              {
                color: marketImage?.badgeColor ?? ButtonColor.Info,
                value: marketImage?.caption,
              },
            ]}
            variant={QualityChipVariant.BadgeLabelBottom}
          />
        )}
      </Box>
    </Box>
  );
};
