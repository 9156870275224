import { ReactNode, useContext, useMemo, useState, createContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CombinedError, UseQueryState } from 'urql';
import { tableScreenInputFromString, tableScreenInputToString, TableScreenQueryParam } from 'screens/TablesScreen';
import ErrorSnackbar from 'components/ErrorSnackbar';
import {
  ShipmentsNewQuery,
  ShipmentsQuery,
  TablesScreenInput,
  useShipmentsNewQuery,
  useShipmentsQuery,
} from 'generated/graphql';
import useBackoffInterval from './useBackoffInterval';
import { useFeatures } from './useFeatures';
import useProfileConfig from './useProfileConfig';
import useSearch from './useSearch';
import { useSyncedMutableOfflineState } from './useSyncedMutableOfflineState';
import useUser from './useUser';

const Context = createContext<{
  fetching: boolean;
  isRevalidating: boolean;
  error: CombinedError | undefined;
  shipments: ShipmentsQuery['shipments'] | undefined;
  result: UseQueryState<ShipmentsQuery | ShipmentsNewQuery>;
  onRefetch: () => void;
  input: TablesScreenInput;
  onInputChange: (input: TablesScreenInput) => void;
} | null>(null);

export const ShipmentsProvider = ({ children }: { children: ReactNode }) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const location = useLocation();
  const { isPendingReview, isRegistering, user, isAdmin } = useUser();
  const [getParam, setParam] = useSearch();
  const [input, setInput] = useState<TablesScreenInput>(
    tableScreenInputFromString(getParam(TableScreenQueryParam.Input) || 'null') || {
      search: getParam(TableScreenQueryParam.Search) || '',
      tabs: [],
    },
  );

  const isShipments = location.pathname.includes('shipments');
  const isQueryPaused = !input || isPendingReview || isRegistering || !user?.profileId;
  const { isEnabled } = useFeatures();
  const isSellerOrdersEnabled = isEnabled('SellerOrders');
  const { profileID: paramProfileID } = useProfileConfig();

  const profileID = useMemo(
    () => (isAdmin && paramProfileID ? paramProfileID : user?.profileId),
    [isAdmin, paramProfileID, user?.profileId],
  );

  const shipmentsQueryData = useShipmentsQuery({
    variables: { input, profileID: profileID?.toString() ?? '0' },
    pause: isQueryPaused || isSellerOrdersEnabled,
  });

  const shipmentsNewQueryData = useShipmentsNewQuery({
    variables: { input, profileID: profileID?.toString() ?? '0' },
    pause: isQueryPaused || !isSellerOrdersEnabled || !profileID,
  });

  const [result, refetch] = isSellerOrdersEnabled ? shipmentsNewQueryData : shipmentsQueryData;

  useEffect(() => {
    if (!input || !isShipments) return;
    setParam(TableScreenQueryParam.Input, tableScreenInputToString(input));
    setParam(TableScreenQueryParam.Search, input.search);
  }, [input, setParam, isShipments]);

  const shouldRefetch = !!result.data && !result.fetching;
  useBackoffInterval(refetch, shouldRefetch);

  const [shipmentsData] = useSyncedMutableOfflineState(result.data, ['shipments', input]);

  useEffect(() => {
    if (result.error) setErrorMessage(result.error.message);
  }, [result.error]);

  const value = useMemo(
    () => ({
      fetching: result.fetching,
      isRevalidating: result.stale,
      error: result.error,
      shipments: isSellerOrdersEnabled ? shipmentsData?.shipmentsNew : shipmentsData?.shipments,
      onRefetch: refetch,
      input,
      onInputChange: setInput,
      result,
    }),
    [result, refetch, input, setInput, shipmentsData, isSellerOrdersEnabled],
  );

  return (
    <Context.Provider value={value}>
      {children}
      <ErrorSnackbar isOpen={!!errorMessage} onClose={() => setErrorMessage(undefined)}>
        {errorMessage}
      </ErrorSnackbar>
    </Context.Provider>
  );
};

export const useShipments = () => {
  const context = useContext(Context);
  if (!context) throw new Error('useShipments must be used within a ShipmentsProvider');
  return context;
};
