import { authExchange } from '@urql/exchange-auth';
import { signOut } from 'firebase/auth';
import { auth } from 'services/firebase';
import { hasRobotToken } from 'utils/hasRobotToken';
import refreshFirebaseUser from 'utils/refresh-firebase-user';
import { clearStore } from './idb-store';
import { adminProfileToIdMap, getThemeVariant } from './theming';

export const getToken = async () => {
  // If a "robotToken" is present in the window hash, use it as the token.
  // Used for PDF generation and other cloud jobs that need to authenticate
  if (hasRobotToken()) {
    const robotToken = window.location.search.split('robotToken=')[1];
    return `robotToken=${robotToken}`;
  }

  const firebaseUser = auth.currentUser;
  if (!firebaseUser) return null;
  const token = await refreshFirebaseUser({
    user: firebaseUser,
  });
  return token;
};

export const logout = async (withReload = true) => {
  await signOut(auth);
  localStorage.clear();
  await clearStore();
  sessionStorage.clear();
  if (withReload) {
    window.location.reload();
  }
};

const authEx = authExchange(async (utils) => {
  let token = await getToken();
  return {
    addAuthToOperation(operation) {
      const withPlatform = utils.appendHeaders(operation, {
        'X-Platform': adminProfileToIdMap[getThemeVariant()]?.toString() ?? '',
      });
      if (token) {
        return utils.appendHeaders(withPlatform, {
          Authorization: `Bearer ${token}`,
        });
      }
      return withPlatform;
    },
    willAuthError() {
      return !token;
    },
    didAuthError(error) {
      const isUnauthorized = error.graphQLErrors.some((e) => e.extensions?.code === 'UNAUTHORIZED');
      if (isUnauthorized) {
        logout(true);
        return false;
      }

      return error.graphQLErrors.some((e) => e.extensions?.code === 'FORBIDDEN');
    },
    async refreshAuth() {
      try {
        token = await getToken();
      } catch {
        await logout(true);
      }
    },
  };
});

export default authEx;
