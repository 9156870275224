import { useState } from 'react';
import { CUSTOM_SCHEME_PATH, CUSTOM_SCHEME_PREFIX } from 'services/api-constants';
import { logError } from 'services/logging';
import { useClient } from 'urql';
import { ConfirmDialogVariant, FormDialogState } from 'components/FormDialog';
import { FormType, TableDialog } from 'generated/graphql';
import useHandleLink from './useHandleLink';
import useHandleMethod from './useHandleMethod';
import useHandleTableDialog from './useHandleTableDialog';

const isCustomScheme = (url: string): boolean =>
  Object.values(CUSTOM_SCHEME_PREFIX).some((scheme) => url.startsWith(scheme));

interface CustomSchemeFields {
  confirmDialogVariant?: ConfirmDialogVariant;
}
interface CustomSchemeActions {
  setFormDialog?: (value: FormDialogState) => void;
  onExpandRow?: () => void;
  onHandleAlert?: () => void;
}

const handleForm = (urlEntity: URL, actions: CustomSchemeActions, fields: CustomSchemeFields) => {
  if (!actions.setFormDialog) {
    return;
  }
  const formType = urlEntity.searchParams.get('type') as FormType;
  let ids: string[] = [];
  try {
    const idsJson = urlEntity.searchParams.get('ids');
    if (idsJson) {
      ids = JSON.parse(idsJson) as string[];
    }
  } catch (e) {
    logError('Failed to parse IDs from URL:', e);
    ids = [];
  }

  let eTags: string[] = [];
  try {
    const eTagsJson = urlEntity.searchParams.get('eTags');
    if (eTagsJson) {
      eTags = JSON.parse(eTagsJson) as string[];
    }
  } catch (e) {
    logError('Failed to parse eTags from URL:', e);
    eTags = [];
  }
  actions.setFormDialog({
    open: true,
    editIDs: ids,
    eTags: eTags,
    type: formType,
    ...(fields.confirmDialogVariant ? { confirmDialogVariant: fields.confirmDialogVariant } : {}),
  });
};

const useHandleCustomSchemeAndQuery = (onRefresh?: () => void, onCustomSchemeRequest?: (url: string) => void) => {
  const client = useClient();
  const handleMethod = useHandleMethod();
  const handleLink = useHandleLink();
  const handleTableDialog = useHandleTableDialog();

  const [paymentDetailsDialogShipmentId, setPaymentDetailsDialogShipmentId] = useState('');
  const [paymentDetailsDialogOpen, setPaymentDetailsDialogOpen] = useState(false);
  const [tableDialog, setTableDialog] = useState<TableDialog>();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleCustomScheme = (url: string, actions: CustomSchemeActions, fields: CustomSchemeFields) => {
    const urlEntity = new URL(url);

    if (
      urlEntity.pathname.startsWith(CUSTOM_SCHEME_PATH.ShipmentTable) ||
      urlEntity.pathname.startsWith(CUSTOM_SCHEME_PATH.TableDialog)
    ) {
      handleTableDialog(urlEntity, setTableDialog, setErrorMessage);
    }

    if (url.startsWith(CUSTOM_SCHEME_PREFIX.Dialog) && urlEntity.pathname.startsWith(CUSTOM_SCHEME_PATH.Form)) {
      handleForm(urlEntity, actions, fields);
    }
    if (url.startsWith(CUSTOM_SCHEME_PREFIX.Method)) {
      handleMethod(urlEntity, setIsLoading, setErrorMessage, onRefresh);
    }
    if (urlEntity.pathname.startsWith(CUSTOM_SCHEME_PATH.PayNow)) {
      setPaymentDetailsDialogOpen(true);
      setPaymentDetailsDialogShipmentId(urlEntity.searchParams.get('shipmentID') || '');
    }

    if (urlEntity.pathname.startsWith(CUSTOM_SCHEME_PATH.ExpandRow)) {
      actions.onExpandRow && actions.onExpandRow();
    }

    if (
      url.startsWith(CUSTOM_SCHEME_PREFIX.Method) &&
      urlEntity.pathname.trim().startsWith(CUSTOM_SCHEME_PATH.Notify) &&
      actions.onHandleAlert
    ) {
      actions.onHandleAlert();
    }

    if (onCustomSchemeRequest) {
      onCustomSchemeRequest(url);
    }
  };

  const handleQuery = async (query: string) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const result = await client.mutation(query, undefined).toPromise();
      if (result.error?.message) {
        setErrorMessage(result.error.message);
        return result;
      }
      if (onRefresh) onRefresh();
    } catch (e) {
      logError('Query handling failed:', e);
      setErrorMessage('An error occurred while processing the query.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSchemeOrQuery = (
    url = '',
    query = '',
    actions: CustomSchemeActions = {},
    fields: CustomSchemeFields = {},
  ) => {
    if (query.length > 0) {
      return handleQuery(query);
    }

    if (isCustomScheme(url)) {
      handleCustomScheme(url, actions, fields);
    } else if (url.length > 0) {
      handleLink(url);
    }

    return null;
  };

  return {
    handleSchemeOrQuery,
    errorMessage,
    isLoading,
    setErrorMessage,
    paymentDetails: {
      open: paymentDetailsDialogOpen,
      id: paymentDetailsDialogShipmentId,
      onClose: () => setPaymentDetailsDialogOpen(false),
    },
    tableDialog: {
      content: tableDialog,
      clear: () => setTableDialog(undefined),
    },
  };
};

export default useHandleCustomSchemeAndQuery;
