import { Alert, Box, Snackbar, Stack } from '@mui/material';
import { onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
import { ReactNode, useState } from 'react';
import { auth } from 'services/firebase';
import ScreenCard from 'screens/Auth/ScreenCard';
import useUser from 'hooks/useUser';
import { MOBILE_FOOTER_HEIGHT } from 'utils/constants';
import Button from '../Button';
import VerifyEmailImage from './verify-email.svg?react';

interface VerifyEmailProps {
  children: ReactNode;
}

const VerifyEmail = ({ children }: VerifyEmailProps) => {
  const { user } = useUser();
  const [message, setMessage] = useState<string>();
  const [isVerfied, setIsVerified] = useState(user?.emailVerified);
  const [emailSent, setEmailSent] = useState(false);

  if (isVerfied) {
    return <>{children}</>;
  }

  const handleResendVerificationEmail = async () => {
    if (!auth.currentUser) {
      return;
    }
    setEmailSent(true);
    setMessage('Email sent, please check your inbox.');
    await sendEmailVerification(auth.currentUser);
  };

  const handleEmailVerified = () => {
    onAuthStateChanged(auth, (authUser) => {
      if (authUser?.emailVerified) {
        setIsVerified(true);
        setMessage('Email is verified');
      } else {
        setMessage('Email has not yet been verified');
      }
    });
  };

  return (
    <Stack alignItems="center" justifyContent="center">
      <ScreenCard
        title="Verify your email"
        subTitle="We’ve sent a verification link to your email address. Please check your inbox and click on the link to verify your email for Nile. If you don't see the email, please check your spam or junk folder."
        alignText="center"
        hasMobileHeader
      >
        <Stack justifyContent="space-between" gap={2}>
          <Stack width={'100%'} gap={1} sx={{ marginTop: 4 }}>
            <Button variant="contained" onClick={handleResendVerificationEmail} disabled={emailSent}>
              Send verification email
            </Button>
            <Button variant="contained" onClick={handleEmailVerified}>
              Check verification status
            </Button>
          </Stack>

          <Box
            sx={(theme) => ({
              marginX: -3,
              marginTop: 3,
              marginBottom: -5,
              [theme.breakpoints.down('md')]: {
                position: 'absolute',
                width: '100vw',
                marginX: -2,
                bottom: '85px',
              },
            })}
          >
            <VerifyEmailImage width={'100%'} height={'100%'} />
          </Box>
        </Stack>
      </ScreenCard>
      <Snackbar
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            bottom: MOBILE_FOOTER_HEIGHT + 8,
          },
        })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={!!message}
        autoHideDuration={3000}
        onClose={() => setMessage(undefined)}
      >
        <Alert onClose={() => setMessage(undefined)} severity="info">
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default VerifyEmail;
