import { Table, TableContainer, TableFooter, TableRow, LinearProgress, Box, Typography, Stack } from '@mui/material';
import { ReactNode, useState } from 'react';
import { ConfirmDialogVariant, FormDialogState } from 'components/FormDialog';
import LazyFormDialog from 'components/LazyFormDialog';
import { defaultFormDialogState } from 'components/default-form-dialog-state';
import { useFeatures } from 'hooks/useFeatures';
import useHandleCustomSchemeAndQuery from 'hooks/useHandleCustomSchemeAndQuery';
import {
  ExpandableTableRow,
  TableCell as TableCellData,
  ExpandableTable as ExpandableTableType,
  TitleVariant,
} from 'generated/graphql';
import ExpandableTableActionBar from './ExpandableTableActionBar';
import ExpandableTableGroup from './ExpandableTableGroup';
import ExpandableTableHead from './ExpandableTableHead';
import ExpandableTablePending from './ExpandableTablePending';
import { getTableContainerStyle, getTableStyle } from './ExpandableTableStyles';
import ExpandableTableTotals from './ExpandableTableTotals';

export interface TableRowData extends ExpandableTableRow {
  data?: any;
}

export interface TableRowGroup {
  rows: TableRowData[];
  total: string;
  totals?: TableCellData[];
}

interface TableProps {
  table: ExpandableTableType;
  isFetching?: boolean;
  hasPending?: boolean;
  footer?: ReactNode;
  noBorder?: boolean;
  showTitle?: boolean;
  onRefresh?: () => void;
  onActionClick?(actionCode: string, rowIds: string[]): void;
  onFieldUpdate?(rowId: string, cellIndex: number, newValue: string): void;
  onCustomSchemeRequest?(url: string): void;
  setShowGraphs?(showGraph: boolean): void;
}

const ExpandableTableNew = ({
  table,
  isFetching = false,
  hasPending = false,
  footer,
  noBorder = false,
  showTitle = false,
  onRefresh = () => {},
  onActionClick = () => {},
  onFieldUpdate = () => {},
  onCustomSchemeRequest = () => {},
  setShowGraphs = () => {},
}: TableProps) => {
  const { isEnabled } = useFeatures();
  const isDispatchRedesignEnabled = isEnabled('DispatchRedesign');

  const { columns, groups, tableActions, totals, type } = table;

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [formDialog, setFormDialog] = useState<FormDialogState>(defaultFormDialogState);
  const { handleSchemeOrQuery } = useHandleCustomSchemeAndQuery(onRefresh);

  const handleFormSubmit = () => {
    setFormDialog(defaultFormDialogState);
    onRefresh();
  };

  if (!table) return null;

  const showTopRow = showTitle || hasPending || selectedRows.length > 0 || tableActions?.length > 0;
  return (
    <>
      {isDispatchRedesignEnabled ? (
        showTopRow && (
          <Stack
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={(theme) => ({
              marginY: theme.spacing(1),
            })}
          >
            <Typography
              variant="body1"
              sx={(theme) => ({
                fontWeight:
                  table.variant === TitleVariant.Bold
                    ? theme.typography.fontWeightBold
                    : theme.typography.fontWeightRegular,
                color:
                  table.variant === TitleVariant.Highlighted ? theme.palette.primary.main : theme.palette.text.primary,
              })}
            >
              {showTitle ? table.title : ''}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {hasPending && <ExpandableTablePending />}
              <ExpandableTableActionBar
                actions={tableActions}
                disabled={hasPending}
                selectedRowIds={selectedRows}
                onActionClick={onActionClick}
                setFormDialog={setFormDialog}
              />
            </Box>
          </Stack>
        )
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {hasPending && <ExpandableTablePending />}
          <ExpandableTableActionBar
            actions={tableActions}
            disabled={hasPending}
            selectedRowIds={selectedRows}
            onActionClick={onActionClick}
            setFormDialog={setFormDialog}
          />
        </Box>
      )}
      <TableContainer sx={{ ...getTableContainerStyle(type) }}>
        {isFetching && <LinearProgress color="secondary" />}
        <Table
          sx={{
            border: noBorder ? 'none' : '', // TODO: Need to remove noBorder
            ...getTableStyle(type),
          }}
        >
          <ExpandableTableHead
            type={type}
            columns={columns}
            tableActions={tableActions}
            onTagClick={(url: string) => handleSchemeOrQuery(url, '', { setFormDialog: setFormDialog })}
          />
          {groups?.map((group, i) => {
            return (
              <ExpandableTableGroup
                key={i}
                groups={groups}
                group={group}
                index={i}
                isFetching={isFetching}
                hasPending={hasPending}
                columns={columns}
                type={type}
                footer={footer}
                actions={tableActions}
                refresh={onRefresh}
                onFieldUpdate={onFieldUpdate}
                onCustomSchemeRequest={onCustomSchemeRequest}
                setShowGraphs={setShowGraphs}
                setSelectedRows={setSelectedRows}
              />
            );
          })}
          <ExpandableTableTotals totals={totals} />
          <TableFooter>
            <TableRow sx={(theme) => ({ width: '100%', background: theme.palette.background.paper })}>
              {footer}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <LazyFormDialog
        type={formDialog.type}
        editIDs={formDialog.editIDs}
        eTags={formDialog.eTags}
        open={formDialog.open}
        confirmDialogVariant={ConfirmDialogVariant.Default}
        onSubmit={handleFormSubmit}
        onClose={() => {
          setFormDialog(defaultFormDialogState);
        }}
      />
    </>
  );
};

export default ExpandableTableNew;
